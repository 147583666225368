<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'700px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        ref="form"
        :label-width="'80px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="deviceInfoForm.name"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮箱：" prop="email">
              <el-input
                v-model="deviceInfoForm.email"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="电话：" prop="phone">
              <el-input
                v-model="deviceInfoForm.phone"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主题：" prop="subject">
              <el-input
                v-model="deviceInfoForm.subject"
                :readonly="isModeInfo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="内容：" prop="content">
              <el-input
                type="textarea"
                v-model="deviceInfoForm.content"
                :readonly="isModeInfo"
                :autosize="{ minRows: 2, maxRows: 4 }"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
import Store from "@/store";
import config from "@/config";
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      headers: { "X-Auth-Token": Store.state.user.token },
      uploadSrc: config().uploadUrl,
      deviceInfoForm: {
      },
      rules: {
      },
    }
  },
  methods: {
    ...{
      show () {
        this.dialogFormVisible2 = true
        this.$nextTick((_) => {
          this.dialogFormVisible = true
        })
      },
      close () {
        this.isModelEdit = false
        this.isModelAdd = false
        this.isModelInfo = false
        this.dialogFormVisible = false
        this.resetForm()
        this.$nextTick((_) => {
          this.dialogFormVisible2 = false
        })
      },
      add () {
        this.titleTable = '新增banner图'
        this.isModelAdd = true
        this.treeData = []
        this.show()
      },
      edit (data) {
        this.titleTable = '编辑banner图'
        this.isModelEdit = true
        this.deviceInfoForm = { ...data }
        this.show()
      },
      view (data) {
        this.titleTable = '查看'
        this.isModelInfo = true
        this.deviceInfoForm = { ...data }
        this.show()
      },
      // 重制
      resetForm () {
        this.deviceInfoForm = {
          name: '',
          imgUrl: ''
        }
        this.$refs.form.resetFields()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.unit-input {
  /deep/.el-form-item__content {
    display: flex;
  }
}
/deep/.el-tree__empty-block {
  display: none;
}
video {
  max-width: 100%;
}
</style>
